import React from 'react';
import { styled } from 'stitches.config';

import { GridSize, GridWidth } from '../../Enums/GridSize.enum';

type PropTypes = {
  children: React.ReactNode;
  type?: GridSize;
  width?: GridWidth;
  noPadding?: boolean;
};

function Grid({ children, type, width, noPadding = true, ...rest }: PropTypes) {
  return (
    <StyledGrid type={type} size={width} {...rest} noPadding={noPadding}>
      {children}
    </StyledGrid>
  );
}
const StyledGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
  margin: 'auto',
  maxW: '$contentMaxWidth',
  mx: 'auto',
  g: 3,
  px: 4,
  variants: {
    type: {
      1: {
        g: 8,
        gridTemplateColumns: '1fr',
      },
      3: {
        g: 3,
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      },
      4: {
        g: 4,
        gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
      },
      6: {
        gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
        g: 6,
      },
      12: {
        gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
        rowGap: 32,
        px: 4,
      },
      ContentContainer: {
        w: '100%',
        mx: 'auto',
        mb: 8,
        p: 0,
        maxW: '$maxWidthMobile',
        gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
        g: 4,

        '@mediaMinMedium': {
          maxW: '$maxWidthTablet',
          gridTemplateColumns: 'repeat(6, minmax(0, 1fr))',
          g: 6,
        },
        '@mediaMinLarge': {
          maxW: '$contentMaxWidth',
          gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
          g: 8,
          p: 2,
        },
      },
    },
    size: {
      screenWidth: {
        maxW: '$screenMaxWidth',
      },
      contentWidth: {
        maxW: '$contentMaxWidth',
      },
      auto: {
        maxWidth: 'auto',
      },
    },
    noPadding: {
      true: {
        px: 0,
      },
    },
  },
});

export default Grid;
